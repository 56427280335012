<template>
  <div class="share-list contain">
    <div
      v-for="item in list"
      :key="item.itemId"
      class="contact-div"
    >
      <div class="contact-dev-left">
        <img src="@/assets/img/icon_discord.png">
      </div>
      <div class="contact-dev-right">
        <div class="contact-dev-right-top">
          <div class="contact-dev-right-top-top">
            {{ item.title }}
          </div>
          <a
            class="contact-dev-right-top-bottom firebaseClickEvent"
            :href="item.link"
          >{{ item.link }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Discords',
  metaInfo: {
    title: 'Discord Group', // set a title
    meta: [
      // { name: 'keyWords', content: 'My Example App' }
    ],
    link: [
      // { rel: 'asstes', href: 'https://assets-cdn.github.com/' }
    ]
  },
  data () {
    return {
      list: [
        // {
        //   title: 'Discord—USA Community',
        //   itemId: 'DiscordUSACommunity',
        //   link: 'https://discord.gg/nPJpxrH'
        // },
        // {
        //   title: 'Discord—中文社區',
        //   itemId: 'DiscordChineseCommunity',
        //   link: 'https://discord.gg/B5fa5J4'
        // },
        {
          title: 'Discord—Global Community',
          itemId: 'DiscordGlobalCommunity',
          link: 'https://discord.gg/ecT3r7wCA3'
        }
      ]
    }
  }
}
</script>

<style lang="less">
  @import "~@/assets/styles/share-list.less";
  .contain{
    background-color: #F4F4F4;
  }
</style>
